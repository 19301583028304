export const getUrlExtension = (url: string): string =>
  url.split(/[#?]/)[0]?.split('.')?.pop()?.trim() || '';

export const urlToFile = async (
  url: string,
  defaultType = 'image/jpeg',
): Promise<File> => {
  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], 'image', {
    type: data.type || defaultType,
  });
};
