import React, { useCallback, useEffect, useMemo, useState } from 'react';

import IOrder from '../../models/IOrder';

import { useToast } from '../../hooks/toast';
import { useAudio } from '../../hooks/audio';
import { useOrders } from '../../hooks/orders';
import { useSidebar } from '../../hooks/sidebar';

import Order from '../../components/Order';
import Search from '../../components/Search';
import PrintModal from '../../components/PrintModal';
import LoadingAnimation from '../../components/LoadingAnimation';
import OrderSummaryModal from '../../components/OrderSummaryModal';
import OrderCancelationModal from '../../components/OrderCancelationModal';

import { PageNames } from '../../enums/pages';
import { filterByValue } from '../../utils/arrays';

import {
  Main,
  Header,
  Content,
  PageInfo,
  PageName,
  Container,
  OrdersContainer,
  NoRecentOrdersMessage,
} from './styles';

const RecentOrdersPage: React.FC = () => {
  const { addToast } = useToast();
  const { setSelectedPage, selectedPage } = useSidebar();

  const {
    recentOrders,
    selectedOrder,
    isRecentOrdersLoading,
    cancelOrder,
    setSelectedOrder,
    loadRecentOrders,
  } = useOrders();

  const { stop, play } = useAudio();
  const [searchCriteria, setSearchCriteria] = useState('');
  const [printModalVisible, setPrintModalVisible] = useState(false);

  const [isOrderSummaryModalVisible, setIsOrderSummaryModalVisible] = useState(
    false,
  );

  const [orderToBeCanceled, setOrderToBeCanceled] = useState(0);

  const handleOnPressClick = useCallback(
    (order: IOrder) => {
      setSelectedOrder(order);
      setPrintModalVisible(true);
    },
    [setSelectedOrder],
  );

  const handleOnClosePrintModal = useCallback(() => {
    setPrintModalVisible(false);
  }, []);

  const handleOnOrderSummaryModalClose = useCallback(() => {
    setIsOrderSummaryModalVisible(false);
    setSelectedOrder(null);
  }, [setSelectedOrder]);

  const handleOnOrderClick = useCallback(
    (order: IOrder) => {
      setSelectedOrder(order);
      setIsOrderSummaryModalVisible(true);
    },
    [setSelectedOrder],
  );

  const handleOnSearchCriteriaChanged = useCallback((value: string) => {
    setSearchCriteria(value);
  }, []);

  const handleOnOrderCancelClick = useCallback((orderId: number) => {
    setOrderToBeCanceled(orderId);
  }, []);

  const handleOnCancelOrderCancel = useCallback(() => {
    setOrderToBeCanceled(0);
  }, []);

  const handleOnCancelOrder = useCallback(
    async (reason: string) => {
      try {
        await cancelOrder(orderToBeCanceled, reason);

        addToast({
          type: 'success',
          description: 'Pedido cancelado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao cancelar o pedido. Tente novamente.',
        });
      } finally {
        setOrderToBeCanceled(0);
      }
    },
    [orderToBeCanceled, cancelOrder, addToast],
  );

  const searchedOrders = useMemo(() => {
    return filterByValue<IOrder>(recentOrders, searchCriteria);
  }, [searchCriteria, recentOrders]);

  useEffect(() => {
    if (recentOrders.length <= 0) {
      stop();
    }

    return () => {
      if (recentOrders.length > 0) {
        play();
      }
    };
  }, [recentOrders, play, stop]);

  useEffect(() => {
    setSelectedPage(PageNames.RECENT_ORDERS);
  }, [setSelectedPage]);

  useEffect(() => {
    loadRecentOrders();
  }, [loadRecentOrders]);

  return (
    <Container>
      <Content>
        <Header>
          <PageInfo>
            <PageName>{selectedPage}</PageName>
          </PageInfo>
          <Search
            value={searchCriteria}
            onChange={handleOnSearchCriteriaChanged}
          />
        </Header>
        {searchedOrders.length === 0 && !isRecentOrdersLoading ? (
          <Main>
            <NoRecentOrdersMessage>
              <span>Nenhum pedido feito recentemente.</span>
            </NoRecentOrdersMessage>
          </Main>
        ) : (
          <Main>
            {isRecentOrdersLoading ? (
              <LoadingAnimation />
            ) : (
              <>
                <OrdersContainer className="has-custom-scroll-bar-2">
                  {searchedOrders.map(order => (
                    <Order
                      type="RECENT"
                      order={order}
                      key={order.id}
                      onClick={handleOnOrderClick}
                      onPressClick={handleOnPressClick}
                      onCancelClick={handleOnOrderCancelClick}
                    />
                  ))}
                </OrdersContainer>
              </>
            )}
          </Main>
        )}
        <OrderSummaryModal
          order={selectedOrder}
          visible={isOrderSummaryModalVisible}
          onClose={handleOnOrderSummaryModalClose}
        />
      </Content>
      <PrintModal open={printModalVisible} onCancel={handleOnClosePrintModal} />
      {orderToBeCanceled > 0 && (
        <OrderCancelationModal
          open={orderToBeCanceled > 0}
          onConfirm={handleOnCancelOrder}
          onCancel={handleOnCancelOrderCancel}
        />
      )}
    </Container>
  );
};

export default RecentOrdersPage;
