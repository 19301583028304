import styled, { css } from 'styled-components';
import Withdrawal from '../../assets/icons/pick-up.svg';
import Delivery from '../../assets/icons/delivery-bike.svg';
import Refresh from '../../assets/icons/refresh.svg';
import RefreshWhite from '../../assets/icons/refresh-white.svg';

interface IIconProps {
  icon: 'delivery' | 'pickUp';
}

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  border-radius: 16px;
  padding: 0px 4px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 110px;
  padding: 0px 4px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield;
    width: 100%;
    border: 0;
    font-size: 1.7rem;
  }
`;

export const Icon = styled.div<IIconProps>`
  width: calc(56px - 1vw);
  height: calc(56px - 1vw);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon === 'delivery' ? Delivery : Withdrawal});
    `}

  :hover {
    cursor: pointer;
  }
`;

export const Button = styled.div`
  width: calc(44px - 1vw);
  height: calc(44px - 1vw);
  background-image: url(${Refresh});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 0;
  border-radius: 5px;

  :hover {
    background: ${({ theme }) => theme.palette.primary};
    background-image: url(${RefreshWhite});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;
