import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: min(1920px, 90vh);
  aspect-ratio: 9 / 16;
  background: #000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface IImageProps {
  visible?: boolean;
}

export const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const animation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const AnimatedImage = styled(Image)<IImageProps>`
  animation: ${animation} 1s ease;
`;

export const TouchIndicator = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  align-self: center;
  margin: auto;
  border-radius: 1vh;
  padding: 1vh;

  h3 {
    margin-top: 6vh;
    font-weight: 500;
    font-size: 1.25vh;
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const TouchAnimationContainer = styled.div`
  position: absolute;
  height: 10vh;
  width: 10vh;
  justify-content: center;
  align-items: center;
  transform: translateY(-2.5vh);
`;

export const PaymentCards = styled.div`
  padding: 0 4.1vh;
  width: 100%;
  height: 3.15vh;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 1.8vh;
  overflow: hidden;

  img {
    height: 100%;
    width: 3.1vh;
    object-fit: contain;
  }
`;
