/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import VMasker from 'vanilla-masker';
import { useTheme } from 'styled-components';
import { FiMoreVertical, FiX } from 'react-icons/fi';

import Spinner from 'react-spinner-material';
import IOrder from '../../models/IOrder';
import IOrderItem from '../../models/IOrderItem';

import Span from '../Span';
import OrderItem from '../OrderItem';
import OptionsDialog from '../OptionsDialog';
import KitchenPrintItem from '../KitchenPrintItem';
import DeliveryPrintItem from '../DeliveryPrintItem';
import ConfirmActionDialog from '../ConfirmActionDialog';
import SummarizedPrintItem from '../SummarizedPrintItem';
import OrderCancelationModal from '../OrderCancelationModal';

import { useToast } from '../../hooks/toast';
import { useOrders } from '../../hooks/orders';
import { useCompany } from '../../hooks/company';

import { AddressType } from '../../enums/addressType';
import { EOrderStatus } from '../../enums/order';
import { getNextStatusMessage } from '../../utils/orders';
import { formatToDocument, formatToMoney } from '../../utils/format';

import AccessDeniedDialog from '../AccessDeniedDialog';

import {
  getFormattedStatus,
  getPaymentTypeName,
  getDeliveryTypeName,
} from '../../utils/string';

import {
  Main,
  Header,
  Divisor,
  Section,
  Printing,
  Container,
  OrderNumber,
  Information,
  PrintPreview,
  SectionTitle,
  ActionsButton,
  PrintingTitle,
  SectionTitles,
  PrintingOption,
  TitleContainer,
  InformationTitle,
  OrderTimeLoading,
  CancelOrderButton,
  HorizontalWrapper,
  PrintPreviewRagged,
  StatusChangeButton,
  OrderTimeContainer,
  PrintingOptionsContainer,
  Label,
} from './styles';

interface IOrderSummaryModalProps {
  visible: boolean;
  order: IOrder | null;
  onClose: () => void;
}

const OrderSummaryModal: React.FC<IOrderSummaryModalProps> = ({
  order,
  visible,
  onClose,
}) => {
  const theme = useTheme();
  const { addToast } = useToast();
  const { company } = useCompany();
  const {
    deleteItem,
    cancelOrder,
    changeStatus,
    changeOrderTime,
  } = useOrders();

  const [page, setPage] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [accessDialog, setAccessDialog] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(0);
  const [orderToBeCanceled, setOrderToBeCanceled] = useState(0);
  const [previousPage, setPreviousPage] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [orderStatus, setOrderStatus] = useState<EOrderStatus>('PLACED');
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  const [isActionsDialogVisible, setIsActionsDialogVisible] = useState(false);

  const orderLoadingRef = useRef<HTMLDivElement>(null);
  const orderTimeRef = useRef<HTMLInputElement>(null);

  const [procedure, setProcedure] = useState<
    'ORDER_CANCELATION' | 'ITEM_DELETE'
  >('ORDER_CANCELATION');

  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState(
    '',
  );

  const [selectedPrintVersion, setSelectedPrintVersion] = useState<
    'DELIVERY' | 'KITCHEN'
  >('KITCHEN');

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  useEffect(() => {
    setSelectedPrintVersion(
      !company?.printKitchenLayout ? 'DELIVERY' : 'KITCHEN',
    );
  }, [company]);

  const handleNavigateTo = useCallback(
    (target: 1 | 2 | 3 | 4 | 5) => {
      setPreviousPage(page);
      setPage(target);
    },
    [page],
  );

  const nextStatus = useMemo(() => {
    switch (orderStatus) {
      case 'PLACED':
        return 'PREPARING';
      case 'PREPARING':
        return 'IN_TRANSIT';
      case 'IN_TRANSIT':
        return 'COMPLETED';
      default:
        return 'PREPARING';
    }
  }, [orderStatus]);

  useEffect(() => {
    if (visible && order) {
      setOrderStatus(order.status);
    }
  }, [visible, order]);

  const handleOnCloseDialog = useCallback(() => {
    setAccessDialog(false);
  }, []);

  const handleOnStatusChange = useCallback(async () => {
    if (company?.pdvIntegration) {
      console.log('tomas');
      setAccessDialog(true);
      return;
    }
    console.log('lomas');

    try {
      if (orderStatus === 'COMPLETED') return;

      await changeStatus(nextStatus, order?.id || 0);

      addToast({
        type: 'success',
        description: 'Você alterou o status do pedido.',
      });

      setOrderStatus(nextStatus);
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;
      console.log('err', err);

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [addToast, changeStatus, order, nextStatus, orderStatus, company]);

  const handleOnItemDeleteClick = useCallback((id: number) => {
    setItemToBeDeleted(id);
    setProcedure('ITEM_DELETE');
    setConfirmationDialogTitle('Exclusão de item');
    setConfirmationDialogMessage(`Deseja excluir o item?`);
  }, []);

  const handleConfirmationDialogClose = useCallback(() => {
    setConfirmationDialogTitle('');
    setConfirmationDialogMessage('');
  }, []);

  const handleOnOrderCancelClick = useCallback((orderId: number) => {
    setOrderToBeCanceled(orderId);
  }, []);

  const handleOnCancelOrderCancel = useCallback(() => {
    setOrderToBeCanceled(0);
  }, []);

  const handleOnCancelOrder = useCallback(
    async (reason: string) => {
      try {
        await cancelOrder(orderToBeCanceled, reason);

        addToast({
          type: 'success',
          description: 'Pedido cancelado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao cancelar o pedido. Tente novamente.',
        });
      } finally {
        setOrderToBeCanceled(0);
      }
    },
    [orderToBeCanceled, cancelOrder, addToast],
  );

  const handleOnDeleteItem = useCallback(async () => {
    try {
      await deleteItem(itemToBeDeleted, order?.id || 0);

      handleConfirmationDialogClose();

      addToast({
        type: 'success',
        description: 'Você cancelou o item.',
      });
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [
    order,
    itemToBeDeleted,
    addToast,
    deleteItem,
    handleConfirmationDialogClose,
  ]);

  const handleOnConfirmDialog = useCallback(() => {
    switch (procedure) {
      case 'ITEM_DELETE':
        handleOnDeleteItem();
        break;
      default:
        handleOnOrderCancelClick(order?.id || 0);
        break;
    }
  }, [handleOnOrderCancelClick, handleOnDeleteItem, procedure, order]);

  const handleOnActionsButtonClicked = useCallback(() => {
    setIsActionsDialogVisible(true);
  }, []);

  const handleOnOptionSelected = useCallback(
    (option: string) => {
      setIsActionsDialogVisible(false);

      switch (option) {
        case 'STATUS':
          handleOnStatusChange();
          break;
        case 'PRINT':
          document.getElementById('print_button')?.click();
          break;
        default:
          break;
      }
    },
    [handleOnStatusChange],
  );

  const handleOnCancelOrderClick = useCallback(() => {
    handleOnOrderCancelClick(order?.id || 0);
  }, [order, handleOnOrderCancelClick]);

  const handleOnKitchenSelected = useCallback(() => {
    setSelectedPrintVersion('KITCHEN');
  }, []);

  const handleOnDeliverySelected = useCallback(() => {
    setSelectedPrintVersion('DELIVERY');
  }, []);

  const actions = useMemo(() => {
    return [
      {
        ref: 'PRINT',
        title: 'Imprimir',
      },
      {
        ref: 'STATUS',
        title: getNextStatusMessage(order?.status, order?.tableNo || 0),
      },
    ];
  }, [order]);

  const formattedPhone = useMemo(() => {
    if (order && order.phoneNumber) {
      return order?.phoneNumber.length > 10
        ? VMasker.toPattern(order.phoneNumber || '', '(99) 9 9999 9999')
        : VMasker.toPattern(order.phoneNumber || '', '(99) 9999 9999');
    }
    return '';
  }, [order]);

  const updateOrderTime = useCallback(async () => {
    if (orderTimeRef.current) {
      orderLoadingRef.current?.classList.add('visible');
    }
    await changeOrderTime(order, Number(orderTimeRef.current?.value));
    if (orderTimeRef.current) {
      orderLoadingRef.current?.classList.remove('visible');
    }
  }, [changeOrderTime, order]);

  const tableNumber = useMemo(() => {
    if (!order) return '';

    if (order.cardNo) {
      return `Mesa ${order.tableNo} - Comanda ${order.cardNo}`;
    }

    return order.tableNo.toString();
  }, [order]);

  const CorrectDeliverySection = useMemo(
    () => () => {
      if (order?.orderType === 'DELIVERY') {
        return (
          <Section
            thisPage={1}
            visible={page === 1}
            previousPage={previousPage}
          >
            <HorizontalWrapper proportional>
              <Span title="Cliente" content={order?.receiverName || ''} />
              <Span title="Telefone" content={formattedPhone} />
              {order.receiverDocument && (
                <Span
                  title="CPF"
                  content={formatToDocument(order.receiverDocument)}
                />
              )}
            </HorizontalWrapper>
            <HorizontalWrapper>
              <Span
                title="Endereço"
                content={`${order?.streetName},
                ${order?.streetNumber}${
                  order.address2 ? ` - ${order.address2}` : ''
                }
                ${
                  order.addressReference ? ` - ${order.addressReference}` : ''
                }`}
              />
              <Span title="Bairro" content={`${order?.neighborhood}`} />
              {order.deliveryType === AddressType.PLACE && (
                <Span
                  title="Bairro selecionado"
                  content={`${order?.deliveryPlace.name}`}
                />
              )}
            </HorizontalWrapper>
            <HorizontalWrapper>
              <Span title="Cidade" content={order?.city?.name || ''} />
              <Span title="Estado" content={order?.city?.state || ''} />
            </HorizontalWrapper>
            {!!order.comments && (
              <Span title="Observações" content={order.comments} />
            )}
            {order?.scheduledProduct ? (
              <>
                <Span
                  title="Horário da entrega"
                  content={order.scheduledProduct}
                />
              </>
            ) : (
              <>
                {!['CANCELED', 'COMPLETED'].includes(order.status) && (
                  <OrderTimeContainer>
                    <label htmlFor="timeToDelivery">
                      Tempo de entrega (em minutos)
                    </label>
                    <div className="input">
                      <input
                        id="timeToDelivery"
                        type="number"
                        ref={orderTimeRef}
                        defaultValue={order?.timeToDelivery}
                      />
                      <button type="submit" onClick={updateOrderTime}>
                        <OrderTimeLoading ref={orderLoadingRef}>
                          <Spinner
                            color="white"
                            radius={18}
                            stroke={3}
                            visible
                          />
                        </OrderTimeLoading>
                        Atualizar
                      </button>
                    </div>
                  </OrderTimeContainer>
                )}
              </>
            )}
          </Section>
        );
      }

      if (
        order?.orderType === 'CHECKOUT' ||
        order?.orderType === 'DRIVE_THRU'
      ) {
        return (
          <Section
            thisPage={1}
            visible={page === 1}
            previousPage={previousPage}
          >
            <Span
              title="Tipo de Entrega"
              content={order.orderType === 'CHECKOUT' ? 'Retira' : 'Drive Thru'}
            />
            {order.phoneNumber ? (
              <>
                <HorizontalWrapper>
                  <Span title="Cliente" content={order.receiverName} />
                  <Span
                    title="Telefone"
                    content={VMasker.toPattern(
                      order.phoneNumber,
                      order.phoneNumber.length > 10
                        ? '(99) 9 9999 9999'
                        : '(99) 9999 9999',
                    )}
                  />
                  {order.receiverDocument && (
                    <Span
                      title="CPF"
                      content={formatToDocument(order.receiverDocument)}
                    />
                  )}
                </HorizontalWrapper>
                <HorizontalWrapper>
                  {!!order.comments && (
                    <Span title="Observações" content={order.comments} />
                  )}
                </HorizontalWrapper>
              </>
            ) : (
              <Span title="Cliente" content={order.receiverName} />
            )}
            {order?.scheduledProduct ? (
              <>
                <Span
                  title="Horário da entrega"
                  content={order.scheduledProduct}
                />
              </>
            ) : (
              <>
                {!['CANCELED', 'COMPLETED'].includes(order.status) && (
                  <OrderTimeContainer>
                    <label htmlFor="timeToPickup">
                      Tempo de retirada (em minutos)
                    </label>
                    <div className="input">
                      <input
                        id="timeToPickup"
                        type="number"
                        ref={orderTimeRef}
                        defaultValue={order?.timeToPickup}
                      />
                      <button type="submit" onClick={updateOrderTime}>
                        <OrderTimeLoading ref={orderLoadingRef}>
                          <Spinner
                            color="white"
                            radius={18}
                            stroke={3}
                            visible
                          />
                        </OrderTimeLoading>
                        Atualizar
                      </button>
                    </div>
                  </OrderTimeContainer>
                )}
              </>
            )}
          </Section>
        );
      }

      return (
        <Section thisPage={1} visible={page === 1} previousPage={previousPage}>
          <Span
            title={order?.tableNo ? 'Mesa' : 'Módulo'}
            content={order?.tableNo ? tableNumber : 'BS Ticket'}
          />
        </Section>
      );
    },
    [order, page, formattedPhone, previousPage, tableNumber, updateOrderTime],
  );

  const deliveryFee = useMemo(() => {
    if (order?.deliveryType === AddressType.PLACE) {
      return `+${formatToMoney(order?.deliveryFee || 0)} (${
        order?.deliveryPlace.name
      })`;
    }

    return `+${formatToMoney(order?.deliveryFee || 0)}`;
  }, [order]);

  const couponType = useMemo(() => {
    if (
      order?.discountCoupon &&
      order.discountCoupon.operationType === 'TOTAL'
    ) {
      return 'Total do pedido';
    }

    return 'Taxa de entrega grátis';
  }, [order]);

  const amountType = useMemo(() => {
    if (
      order?.discountCoupon &&
      order.discountCoupon.operationType === 'TOTAL'
    ) {
      if (order.discountCoupon.amountType === 'FIXED') {
        return 'Valor Fixo';
      }

      return 'Porcentagem';
    }

    return '';
  }, [order]);

  const brand = useMemo(() => {
    return company?.paymentCards.find(
      currentBrand => currentBrand.paymentCardId === order?.cardBrandId,
    );
  }, [company, order]);

  return (
    <Container visible={visible}>
      <Header>
        <div>
          <FiX size={28} onClick={onClose} />
          <OrderNumber>{`Pedido #${order?.number}`}</OrderNumber>
        </div>
        {orderStatus !== 'COMPLETED' && (
          <StatusChangeButton onClick={handleOnStatusChange}>
            {getNextStatusMessage(orderStatus, order?.tableNo || 0)}
          </StatusChangeButton>
        )}
      </Header>
      <Main className="has-custom-scroll-bar">
        <Information className="has-custom-scroll-bar-2">
          <TitleContainer>
            <InformationTitle>Informações do pedido</InformationTitle>
            {orderStatus !== 'CANCELED' && orderStatus !== 'COMPLETED' && (
              <CancelOrderButton onClick={handleOnCancelOrderClick}>
                <span />
              </CancelOrderButton>
            )}
          </TitleContainer>
          <SectionTitles>
            <SectionTitle
              onClick={() => handleNavigateTo(1)}
              selected={page === 1}
            >
              Entrega
            </SectionTitle>
            <SectionTitle
              onClick={() => handleNavigateTo(2)}
              selected={page === 2}
            >
              Pagamento
            </SectionTitle>
            <SectionTitle
              onClick={() => handleNavigateTo(3)}
              selected={page === 3}
            >
              Itens
            </SectionTitle>
            <SectionTitle
              onClick={() => handleNavigateTo(4)}
              selected={page === 4}
            >
              Desconto
            </SectionTitle>
            {order?.status === 'CANCELED' && (
              <SectionTitle
                onClick={() => handleNavigateTo(5)}
                selected={page === 5}
              >
                Cancelamento
              </SectionTitle>
            )}
          </SectionTitles>
          <CorrectDeliverySection />
          <Section
            thisPage={2}
            visible={page === 2}
            previousPage={previousPage}
          >
            <HorizontalWrapper>
              <Span
                title="Tipo de entrega"
                content={getDeliveryTypeName(order?.orderType || '')}
              />
              <Span
                title="Status"
                content={getFormattedStatus(order?.status || 'PLACED')}
              />
            </HorizontalWrapper>
            <HorizontalWrapper>
              <Span
                title="Subtotal"
                content={formatToMoney(order?.subTotal || 0)}
              />
              {!!order?.discount && order?.discount > 0 && (
                <Span
                  title="Desconto"
                  content={formatToMoney(order?.discount || 0)}
                />
              )}
              {order?.orderType === 'DELIVERY' && (
                <Span title="Entrega" content={deliveryFee} />
              )}
              <Span title="Total" content={formatToMoney(order?.total || 0)} />
            </HorizontalWrapper>

            {(order?.payments || [])?.length > 0 ? (
              <>
                <Label>Pagamentos</Label>
                {order?.payments.map(({ paymentType, amount, id }) => (
                  <HorizontalWrapper
                    key={`${paymentType}-${id}`}
                    style={{ marginTop: 12 }}
                  >
                    <Span
                      title="Tipo de pagamento"
                      content={getPaymentTypeName(paymentType) || paymentType}
                    />
                    <Span
                      title="Valor"
                      content={amount > 0 ? formatToMoney(amount) : '-'}
                    />
                  </HorizontalWrapper>
                ))}
              </>
            ) : (
              <>
                <HorizontalWrapper>
                  <Span
                    title="Tipo de pagamento"
                    content={getPaymentTypeName(order?.paymentType || '')}
                  />
                  <Span
                    title="Troco para"
                    content={
                      order && order.cashAdvance > 0
                        ? formatToMoney(order.cashAdvance)
                        : '-'
                    }
                  />
                </HorizontalWrapper>
                {brand && (
                  <HorizontalWrapper>
                    <Span title="Bandeira" content={brand?.description} />
                  </HorizontalWrapper>
                )}
              </>
            )}
          </Section>
          <Section
            thisPage={3}
            visible={page === 3}
            previousPage={previousPage}
            className="has-custom-scroll-bar-2"
          >
            {order?.items.map((item: IOrderItem) => (
              <OrderItem
                isFinished={
                  order.status === 'COMPLETED' || order.status === 'CANCELED'
                }
                item={item}
                key={item.id}
                hasCoupon={!!order.discountCoupon}
                onDelete={handleOnItemDeleteClick}
              />
            ))}
          </Section>
          <Section
            thisPage={4}
            visible={page === 4}
            previousPage={previousPage}
            className="has-custom-scroll-bar-2"
          >
            {order?.discountCoupon ? (
              <>
                <HorizontalWrapper>
                  <Span title="Tipo do cupom" content={couponType} />
                  {amountType && (
                    <Span title="Tipo do valor" content={amountType} />
                  )}
                  <Span
                    title="Cupom de desconto"
                    content={order?.discountCoupon.ref || ''}
                  />
                </HorizontalWrapper>
                <Span
                  title="Valor aplicado"
                  content={formatToMoney(order.discount || 0)}
                />
              </>
            ) : (
              <span>Nenhum cupom usado nesse pedido.</span>
            )}
          </Section>
          <Section
            thisPage={5}
            visible={page === 5}
            previousPage={previousPage}
          >
            <Span title="Motivo" content={order?.cancelReason || ''} />
          </Section>
        </Information>
        <Printing>
          <PrintingTitle>Impressão</PrintingTitle>
          <PrintingOptionsContainer inverse={!company?.printKitchenLayout}>
            <PrintingOption
              onClick={handleOnKitchenSelected}
              selected={selectedPrintVersion === 'KITCHEN'}
            >
              Cozinha
            </PrintingOption>
            <Divisor>/</Divisor>
            <PrintingOption
              onClick={handleOnDeliverySelected}
              selected={selectedPrintVersion === 'DELIVERY'}
            >
              Entrega
            </PrintingOption>
          </PrintingOptionsContainer>
          <PrintPreview className="has-custom-scroll-bar-3">
            {selectedPrintVersion === 'KITCHEN' ? (
              <KitchenPrintItem order={order} />
            ) : (
              <>
                {company?.isPrintSummedUp ? (
                  <SummarizedPrintItem type="MODAL" order={order} />
                ) : (
                  <DeliveryPrintItem order={order} />
                )}
              </>
            )}
          </PrintPreview>
          <PrintPreviewRagged />
        </Printing>
      </Main>
      <ActionsButton onClick={handleOnActionsButtonClicked}>
        <FiMoreVertical color={theme.palette.text_white} size={24} />
      </ActionsButton>
      {confirmationDialogTitle && (
        <ConfirmActionDialog
          title={confirmationDialogTitle}
          isOpen={!!confirmationDialogTitle}
          message={confirmationDialogMessage}
          onConfirm={handleOnConfirmDialog}
          onClose={handleConfirmationDialogClose}
        />
      )}
      <OptionsDialog
        options={actions}
        title="O que deseja fazer?"
        isOpen={isActionsDialogVisible}
        onConfirm={handleOnOptionSelected}
        onClose={() => setIsActionsDialogVisible(false)}
      />
      <AccessDeniedDialog open={accessDialog} onCancel={handleOnCloseDialog} />
      {orderToBeCanceled > 0 && (
        <OrderCancelationModal
          open={orderToBeCanceled > 0}
          onConfirm={handleOnCancelOrder}
          onCancel={handleOnCancelOrderCancel}
        />
      )}
    </Container>
  );
};

export default OrderSummaryModal;
