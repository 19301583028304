import styled, { css } from 'styled-components';

interface IButtonProps {
  active: boolean;
}

export const Container = styled.div`
  height: 96px;
  border: none;
  text-align: justify;
  position: relative;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 8px);
  /* width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px); */
  overflow: hidden;
  transition: box-shadow 0.4s;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);

  cursor: pointer;

  &:hover {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.div`
  padding: 16px;
`;

export const Image = styled.img`
  width: 3rem;
  height: 3rem;

  @media (max-width: 320px) {
    width: 45px;
    height: 45px;
  }
`;

export const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-right: 16px;

  @media (max-width: 400px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    margin-right: 0;
  }

  @media (max-width: 320) {
    margin-right: 0;
  }
`;

export const DeviceName = styled.strong`
  font-size: 18px;

  @media (max-width: 500px) {
    font-size: 16px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const DeviceLogin = styled.span`
  font-size: 14px;
  color: #bbb;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const ActivateDeviceButton = styled.button<IButtonProps>`
  height: 100%;
  min-width: 90px;
  padding: 8px 16px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ active }) =>
    active
      ? css`
          background-color: #27ae6090;
          transition: background-color 0.2s;
          &:before {
            border-color: transparent transparent #27ae6090 transparent;
          }
        `
      : css`
          background-color: #e74c3c90;
          transition: background-color 0.2s;
          &:before {
            border-color: transparent transparent #e74c3c90 transparent;
          }
        `}

  span {
    margin-top: 16px;
  }

  @media (max-width: 500px) {
    max-width: 300px;
  }

  @media (max-width: 320px) {
    font-size: 16px;
  }

  @media (max-width: 384px) {
    background: transparent;
    position: absolute;
    bottom: 0;
    right: -12px;
    height: 38px;

    padding: 0;

    span {
      display: none;
    }

    svg {
      z-index: 12;
      width: 32px;
      height: 32px;
      stroke: ${({ theme }) => theme.palette.text_white};
    }

    &:before {
      position: absolute;
      bottom: 0;
      right: 12px;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 72px 30vw;
    }
  }
`;
