import styled from 'styled-components';

export const SwitchesContainerWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;

  @media (max-width: 1270px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 8px;
    padding: 16px;
    position: relative;
  }
`;

export const InputTimeDeliveryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (max-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }
  gap: 1rem;
  width: 100%;
`;

export const InputTimeDeliveryWrapper = styled.div`
  @media (max-width: 1270px) {
    display: none;
  }
  @media (max-width: 550px) {
    display: block;
  }
`;
