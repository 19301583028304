import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import {
  InputTimeDeliveryContainer,
  InputTimeDeliveryWrapper,
  SwitchesContainerWrapper,
} from './styles';
import { AuthRole } from '../../hooks/auth';
import { ToggleContainer } from '../Sidebar/styles';
import TimeDelivery from '../TimeDelivery';
import ICompany, { ITimeCompany } from '../../models/ICompany';

interface ISwitchesContainerProps {
  handleToggleCompanyOpen: () => void;
  handleToggleSendWhatsApp: () => void;
  company: ICompany;
  onClick: (times: ITimeCompany) => void;
}

const SwitchesContainer: React.FC<ISwitchesContainerProps> = ({
  company,
  handleToggleCompanyOpen,
  handleToggleSendWhatsApp,
  onClick,
}: ISwitchesContainerProps) => {
  const [timeToDelivery, setTimeToDelivery] = useState<number>(
    company?.timeToDelivery || 0,
  );
  const [timeToPickup, setTimeToPickup] = useState<number>(
    company?.timeToPickup || 0,
  );

  useEffect(() => {
    setTimeToDelivery(company?.timeToDelivery);
    setTimeToPickup(company?.timeToPickup);
  }, [company]);
  return (
    <SwitchesContainerWrapper className="switches-container">
      <AuthRole whiteList={['Manager']}>
        <InputTimeDeliveryContainer>
          <ToggleContainer>
            <Toggle
              className="custom-toggle"
              icons={false}
              onChange={handleToggleCompanyOpen}
              checked={company?.isOpen || false}
            />
            {company?.isOpen ? (
              <span>Empresa aberta</span>
            ) : (
              <span>Empresa fechada</span>
            )}
          </ToggleContainer>
          <InputTimeDeliveryWrapper>
            <TimeDelivery
              onClick={() => onClick({ timeToDelivery, timeToPickup })}
              value={timeToDelivery}
              setValue={setTimeToDelivery}
              icon="delivery"
            />
          </InputTimeDeliveryWrapper>
          <AuthRole>
            {company?.subscription?.plan?.sendWhatsApp && (
              <ToggleContainer>
                <Toggle
                  className="custom-toggle"
                  icons={false}
                  onChange={handleToggleSendWhatsApp}
                  checked={company?.sendWhatsapp || false}
                />
                <span>Envia WhatsApp</span>
              </ToggleContainer>
            )}
          </AuthRole>
          <InputTimeDeliveryWrapper>
            <TimeDelivery
              onClick={() => onClick({ timeToDelivery, timeToPickup })}
              value={timeToPickup}
              setValue={setTimeToPickup}
              icon="pickUp"
            />
          </InputTimeDeliveryWrapper>
        </InputTimeDeliveryContainer>
      </AuthRole>
    </SwitchesContainerWrapper>
  );
};

export default SwitchesContainer;
