/* eslint-disable consistent-return */
import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import {
  LogLevel,
  HubConnection,
  HttpTransportType,
  HubConnectionState,
  HubConnectionBuilder,
} from '@microsoft/signalr';

import { DateTime } from 'luxon';

import IOrder from '../models/IOrder';
import ICompany from '../models/ICompany';
import IOrderDTO from '../dtos/IOrderDTO';

import { useAuth } from './auth';
import { useAudio } from './audio';
import { useToast } from './toast';
import { useInterval } from './time';
import { useCompany } from './company';

import api from '../services/api';
import { EOrderOrigin, EOrderStatus } from '../enums/order';
import { LocalStorage } from '../enums/localstorage';

export type FilterTypes = 'PREPARING' | 'IN_TRANSIT' | 'COMPLETED' | 'CANCELED';

interface OrdersContextData {
  search: string;
  pending: number;
  orders: IOrder[];
  hasMore: boolean;
  filter: FilterTypes[];
  recentOrders: IOrder[];
  loadingStatus: number[];
  isOrdersLoading: boolean;
  isLoadingMoreOrders: boolean;
  selectedOrder: IOrder | null;
  isRecentOrdersLoading: boolean;
  webSocketConnection: HubConnection | null;
  startWebSocket: () => void;
  setSearch: (search: string) => void;
  loadRecentOrders: () => Promise<void>;
  connectToWebSocket: (company: ICompany) => void;
  setSelectedOrder: (order: IOrder | null) => void;
  loadMoreOrders: (lastId?: number) => Promise<void>;
  setFilter: (filter: FilterTypes[], criteria?: string) => void;
  loadOrders: (selectedFilter?: FilterTypes[]) => Promise<void>;
  deleteItem: (itemId: number, orderId: number) => Promise<void>;
  cancelOrder: (orderId: number, reason: string) => Promise<void>;
  changeStatus: (status: EOrderStatus, orderId: number) => Promise<void>;
  changeOrderTime: (order: IOrder | null, value: number) => Promise<void>;
}

const OrdersContext = createContext<OrdersContextData>({} as OrdersContextData);

export const OrdersProvider: React.FC = ({ children }) => {
  const filterRef = useRef<FilterTypes[]>([]);

  const { user } = useAuth();
  const { addToast } = useToast();
  const { company } = useCompany();
  const { play, stop } = useAudio();

  const [
    webSocketConnection,
    setWebSocketConnection,
  ] = useState<HubConnection | null>(null);

  const [search, setSearch] = useState('');
  const [pending, setPending] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(true);
  const [recentOrders, setRecentOrders] = useState<IOrder[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<number[]>([]);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [isLoadingMoreOrders, setIsLoadingMoreOrders] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);
  const [isRecentOrdersLoading, setIsRecentOrdersLoading] = useState(true);

  const [filter, setFilter] = useState<FilterTypes[]>([
    'IN_TRANSIT',
    'PREPARING',
  ]);

  useEffect(() => {
    if (!user) {
      setFilter(['IN_TRANSIT', 'PREPARING']);
    }
  }, [user]);

  const loadOrders = useCallback(
    async (filterOption: FilterTypes[] = [], criteria?: string) => {
      if (company?.id) {
        const normFilter = filterOption.length === 0 ? filter : filterOption;
        setIsOrdersLoading(true);
        const origin =
          localStorage.getItem(LocalStorage.ORDERS_ORIGIN) ||
          EOrderOrigin.BSFOOD;
        const response = await api.get<IOrderDTO>(
          `restricted/orders/${company?.id}`,
          {
            params: {
              status: normFilter.join(','),
              limit: 30,
              ...(criteria && { searchText: criteria }),
              origin,
              types:
                origin === EOrderOrigin.BSSELFCHECKOUT
                  ? localStorage.getItem(LocalStorage.ORDERS_TYPE)
                  : undefined,
            },
          },
        );

        setOrders(response.data.data);
        setPending(response.data.pendingCount);
        setIsOrdersLoading(false);
      }
    },
    [company, filter],
  );

  const loadMoreOrders = useCallback(
    async (lastId?: number) => {
      setIsLoadingMoreOrders(true);
      const origin =
        localStorage.getItem(LocalStorage.ORDERS_ORIGIN) || EOrderOrigin.BSFOOD;
      const response = await api.get<IOrderDTO>(
        `restricted/orders/${company?.id}`,
        {
          params: {
            status: filter.join(','),
            limit: 30,
            ...(lastId && { lastId }),
            ...(search && { searchText: search }),
            origin,
            types:
              origin === EOrderOrigin.BSSELFCHECKOUT
                ? localStorage.getItem(LocalStorage.ORDERS_TYPE)
                : undefined,
          },
        },
      );

      setOrders(old => [...old, ...response.data.data]);
      setPending(response.data.pendingCount);

      setIsLoadingMoreOrders(false);

      if (response.data.totalCount === 0) {
        setHasMore(false);
      }
    },
    [company, filter, search],
  );

  const handleChangeFilter = useCallback(
    (newFilters: FilterTypes[], criteria?: string) => {
      setFilter(newFilters);
      setHasMore(true);
      loadOrders(newFilters, criteria);
      setSearch(criteria || '');
    },
    [loadOrders],
  );

  const handleSetSearch = useCallback((newTerm: string) => {
    setSearch(newTerm);
  }, []);

  const loadRecentOrders = useCallback(async () => {
    const response = await api.get<IOrderDTO>(
      `restricted/orders/${company?.id}`,
      {
        params: {
          status: 'PLACED',
          limit: 1000,
        },
      },
    );

    setRecentOrders(response.data.data);
    setPending(response.data.pendingCount);
    setIsRecentOrdersLoading(false);
  }, [company]);

  const { start: startManualRefresh, stop: stopManualRefresh } = useInterval(
    () => {
      const date = localStorage.getItem(LocalStorage.LAST_ORDERS_REFRESH);

      if (date && typeof date === 'string') {
        const { seconds } = DateTime.fromMillis(Number(date))
          .diffNow('seconds')
          .toObject();

        if (seconds && seconds < -180) {
          localStorage.setItem(
            LocalStorage.LAST_ORDERS_REFRESH,
            String(Date.now()),
          );

          loadOrders();
          loadRecentOrders();
        }
      } else {
        localStorage.setItem(
          LocalStorage.LAST_ORDERS_REFRESH,
          String(Date.now()),
        );

        loadOrders();
        loadRecentOrders();
      }
    },
    60000,
  );

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  const changeStatus = useCallback(
    async (status: EOrderStatus, orderId: number) => {
      setLoadingStatus(old => [...old, orderId]);
      try {
        await api.patch(
          `restricted/orders/${company?.id}/${orderId}/change-status`,
          {
            companyId: company?.id,
            orderId,
            status,
          },
        );

        setLoadingStatus(old => old.filter(item => item !== orderId));

        setOrders(old => {
          if (filterRef?.current.includes(status as FilterTypes)) {
            return old.map(item =>
              item.id !== orderId ? item : { ...item, status },
            );
          }

          return old.filter(item => item.id !== orderId);
        });

        if (status === 'PREPARING' || status === 'CANCELED') {
          setRecentOrders(old => old.filter(item => item.id !== orderId));
        }
      } catch (e) {
        setLoadingStatus(old => old.filter(item => item !== orderId));
        throw e;
      }
    },
    [company],
  );

  const changeOrderTime = useCallback(
    async (order: IOrder | null, value: number) => {
      if (order) {
        try {
          await api.patch(
            `/restricted/orders/${order?.id}/${
              order?.orderType === 'DELIVERY'
                ? 'time-to-delivery'
                : 'time-to-pickup'
            }`,
            { content: value },
          );

          setOrders(old =>
            old.map(item =>
              item.id === order.id
                ? {
                    ...item,
                    ...(order?.orderType === 'DELIVERY'
                      ? { timeToDelivery: value }
                      : { timeToPickup: value }),
                  }
                : item,
            ),
          );
          setRecentOrders(old =>
            old.map(item =>
              item.id === order.id
                ? {
                    ...item,
                    ...(order?.orderType === 'DELIVERY'
                      ? { timeToDelivery: value }
                      : { timeToPickup: value }),
                  }
                : item,
            ),
          );
          addToast({
            type: 'success',
            description: `Tempo de ${
              order?.orderType === 'DELIVERY' ? 'entrega' : 'retirada'
            } alterado com sucesso.`,
          });
        } catch {
          addToast({
            type: 'error',
            description: `Ocorreu um erro ao alterar tempo de ${
              order?.orderType === 'DELIVERY' ? 'entrega' : 'retirada'
            }.`,
          });
        }
      }
    },
    [addToast],
  );

  const deleteItem = useCallback(
    async (itemId: number, orderId: number) => {
      await api.delete(
        `restricted/orders/${company?.id}/${orderId}/${itemId}/item`,
      );

      if (selectedOrder?.items) {
        const deletedItem = selectedOrder?.items.find(
          item => item.id === itemId,
        );

        if (deletedItem) {
          const index = selectedOrder?.items.findIndex(
            item => item.id === deletedItem.id,
          );

          if (index >= 0) {
            const items = selectedOrder && [...selectedOrder.items];
            if (items) {
              items[index].deleted = true;
              const updatedSelectedOrder = selectedOrder;
              if (updatedSelectedOrder) {
                updatedSelectedOrder.items = items;
                setSelectedOrder(updatedSelectedOrder);
              }
            }
          }
        }
      }
    },
    [company, selectedOrder],
  );

  const handleOnNewOrderReceived = useCallback(
    (order: string) => {
      const parsedOrder = JSON.parse(order) as IOrder;
      play();

      setRecentOrders(old => [parsedOrder, ...old]);

      localStorage.setItem(
        LocalStorage.LAST_ORDERS_REFRESH,
        String(Date.now()),
      );

      if (Notification.permission === 'granted') {
        // eslint-disable-next-line no-new
        new Notification('Pedido Recebido!', {
          body: 'Você recebeu um pedido, venha conferir.',
          icon:
            'https://bsfoodstorage.blob.core.windows.net/companies/bsfood-menu-logo-high.png',
        });
      }
    },
    [play],
  );

  const handleTableChangedStatus = useCallback((tableId: number) => {
    // todo
  }, []);

  const handleOnOrderStatusChanged = useCallback((order: string) => {
    const parsedOrder = JSON.parse(order) as IOrder;
    const ordersOrigin =
      localStorage.getItem(LocalStorage.ORDERS_ORIGIN) || EOrderOrigin.BSFOOD;
    const ordersType = localStorage.getItem(LocalStorage.ORDERS_TYPE);

    if (
      parsedOrder?.origin !== ordersOrigin ||
      (ordersOrigin === EOrderOrigin.BSSELFCHECKOUT &&
        parsedOrder?.origin !== ordersType)
    ) {
      return;
    }

    if (filterRef.current.includes(parsedOrder?.status as FilterTypes)) {
      setOrders(old => {
        if (old.find(item => item.id === parsedOrder?.id)) {
          return old.map(item =>
            item.id === parsedOrder?.id
              ? { ...item, status: parsedOrder?.status }
              : item,
          );
        }

        return [parsedOrder, ...old];
      });
    } else {
      setOrders(old => old.filter(item => item.id !== parsedOrder?.id));
    }
    setRecentOrders(old => old.filter(item => item.id !== parsedOrder?.id));
  }, []);

  const handleUserConnected = useCallback((id: string) => {
    api.defaults.headers = {
      ...api.defaults.headers,
      'X-Connection-Id': id,
    };
  }, []);

  const connectToWebSocket = useCallback(
    async (inputCompany: typeof company) => {
      if (
        !inputCompany?.id ||
        inputCompany?.subscription?.plan?.showCase ||
        websocketConnected
      )
        return;

      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}order-placed`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => `${user?.accessToken}`,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect([
          1000,
          5000,
          10000,
          20000,
          20000,
          20000,
          20000,
          20000,
          20000,
          20000,
          20000,
          20000,
          30000,
          30000,
          30000,
          30000,
        ])
        .build();

      setWebSocketConnection(connection);
    },
    [user, company, websocketConnected],
  );

  const cancelOrder = useCallback(
    async (orderId: number, reason: string) => {
      setLoadingStatus(old => [...old, orderId]);
      try {
        await api.patch(`/restricted/orders/${company?.id}/${orderId}/cancel`, {
          reason,
        });

        setLoadingStatus(old => old.filter(item => item !== orderId));

        setOrders(old => {
          if (filterRef?.current.includes('CANCELED' as FilterTypes)) {
            return old.map(item =>
              item.id !== orderId ? item : { ...item, status: 'CANCELED' },
            );
          }

          return old.filter(item => item.id !== orderId);
        });

        setRecentOrders(old => old.filter(item => item.id !== orderId));
      } catch (e) {
        setLoadingStatus(old => old.filter(item => item !== orderId));
        throw e;
      }
    },
    [company],
  );

  useEffect(() => {
    if (!user && webSocketConnection) {
      webSocketConnection
        ?.stop()
        .then(() => {
          setWebsocketConnected(false);
          setWebSocketConnection(null);
        })
        .catch(e => console.log(e));
    }
  }, [user, webSocketConnection]);

  const startWebSocket = useCallback(() => {
    if (
      company?.id &&
      webSocketConnection &&
      webSocketConnection.state === HubConnectionState.Disconnected
    ) {
      webSocketConnection.on('UserConnected', handleUserConnected);
      webSocketConnection.on('TableBusy', handleTableChangedStatus);
      webSocketConnection.on('NewOrderPlaced', handleOnNewOrderReceived);
      webSocketConnection.on('OrderChangeStatus', handleOnOrderStatusChanged);

      webSocketConnection
        .start()
        .then(() => {
          setWebsocketConnected(true);

          webSocketConnection
            .invoke('JoinGroup', company.id.toString())
            .catch(() => {
              setWebsocketConnected(false);
            });
        })
        .catch(() => {
          setWebsocketConnected(false);
        });

      webSocketConnection.onreconnected(() => {
        setWebsocketConnected(true);

        webSocketConnection
          .invoke('JoinGroup', company.id.toString())
          .catch(() => {
            setWebsocketConnected(false);
          });

        loadOrders();
        loadRecentOrders();
      });

      webSocketConnection.onclose(() => {
        setWebsocketConnected(false);
      });
    }
  }, [
    company,
    webSocketConnection,
    loadOrders,
    loadRecentOrders,
    handleUserConnected,
    handleTableChangedStatus,
    handleOnNewOrderReceived,
    handleOnOrderStatusChanged,
  ]);

  useEffect(() => {
    if (user?.id) {
      startManualRefresh();
    } else {
      stopManualRefresh();
    }
  }, [user, startManualRefresh, stopManualRefresh]);

  useEffect(() => {
    startWebSocket();
  }, [startWebSocket]);

  // useEffect(() => {  renderização desnecessária fazendo com que as requisições fossem duplicadas
  //   if (company) {
  //     loadOrders();
  //     loadRecentOrders();
  //   }
  // }, [loadOrders, loadRecentOrders, company]);

  useEffect(() => {
    if (recentOrders.length > 0) {
      play();
    } else {
      stop();
    }
  }, [play, stop, recentOrders]);

  return (
    <OrdersContext.Provider
      value={{
        filter,
        orders,
        search,
        hasMore,
        pending,
        recentOrders,
        loadingStatus,
        selectedOrder,
        isOrdersLoading,
        webSocketConnection,
        isLoadingMoreOrders,
        isRecentOrdersLoading,
        loadOrders,
        deleteItem,
        cancelOrder,
        changeStatus,
        startWebSocket,
        loadMoreOrders,
        changeOrderTime,
        loadRecentOrders,
        setSelectedOrder,
        connectToWebSocket,
        setSearch: handleSetSearch,
        setFilter: handleChangeFilter,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export function useOrders(): OrdersContextData {
  const context = useContext(OrdersContext);

  if (!context) {
    throw new Error('useOrders must be used within OrdersProvider');
  }

  return context;
}
