import React, { useCallback, useMemo, useRef } from 'react';

import { FiPauseCircle, FiPlayCircle, FiTrash } from 'react-icons/fi';
import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';

import { formatToMoney } from '../../utils/format';
import { randomInt } from '../../utils/numbers';

import IProduct from '../../models/IProduct';

import { ECategoryType } from '../../enums/categoryType';
import { AuthRole } from '../../hooks/auth';
import { useCompany } from '../../hooks/company';
import { EPlanModule } from '../../models/IPlan';
import {
  Actions,
  Bottom,
  CategoryBadge,
  ComboBadge,
  Container,
  Description,
  Label,
  Left,
  ListActions,
  ListContainer,
  ListDescription,
  ListImage,
  ListLabel,
  ListName,
  ListPrice,
  ListProductQuantity,
  ListQuantityContainer,
  Name,
  PrepareTime,
  Price,
  ProductImage,
  ProductInfo,
  ProductQuantity,
  QuantityContainer,
  SubcategoryBadge,
  Subtitle,
  Title,
  Top,
  TypesContainer,
  VerticalWrapper,
} from './styles';

interface IProductProps {
  autoScroll?: boolean;
  product: IProduct;
  display: 'GRID' | 'LIST';
  actions: boolean;
  onClick: () => void;
  onDelete: (productId: number) => void;
  onInactivate: (productId: number) => void;
}

const Product: React.FC<IProductProps> = ({
  display,
  product,
  autoScroll,
  actions,
  onClick,
  onDelete,
  onInactivate,
}) => {
  const { company } = useCompany();

  const containerRef = useRef<HTMLButtonElement>(null);

  const handleDeleteProduct = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      event.stopPropagation();
      onDelete(product.id);
    },
    [product, onDelete],
  );

  const handleInactivateProduct = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      event.stopPropagation();
      onInactivate(product.id);
    },
    [product, onInactivate],
  );

  const hasComplements = useMemo(() => {
    return product.complementsGroups.some(
      group =>
        group.minAmount > 0 || group.complements.some(c => c.unitPrice > 0),
    );
  }, [product]);

  const productIcon = useMemo(() => {
    if (product.imageUrl) {
      return <img src={product.imageUrl} alt={product.title} />;
    }

    const classes = ['blue', 'red', 'orange', 'green', 'yellow'];

    const randomClass = classes[randomInt(0, classes.length - 1)];
    return <ProductPlaceholderSVG className={randomClass} />;
  }, [product]);

  const isPizza = useMemo(
    () =>
      product?.categories.some(
        category => category.type === ECategoryType.PIZZA,
      ),
    [product],
  );

  // useEffect(() => {
  //   if (autoScroll) {
  //     containerRef.current?.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //     });
  //   }
  // }, [autoScroll]);

  if (display === 'GRID') {
    return (
      <Container
        onClick={onClick}
        active={product.active}
        ref={containerRef}
        className={autoScroll ? 'focus-animation' : ''}
        actions={actions}
      >
        <ProductImage>
          {productIcon}
          {product.prepareTime && (
            <PrepareTime>
              <span>{product.prepareTime}</span>
            </PrepareTime>
          )}
        </ProductImage>
        <ProductInfo>
          <Title>
            {product.sellerSku ? (
              <Name>{`(${product.sellerSku}) ${product.title}`}</Name>
            ) : (
              <Name>{`${product.title}`}</Name>
            )}
            <Description>{product.description}</Description>
          </Title>
          <Subtitle>
            {company?.subscription?.plan.modules?.includes(
              EPlanModule.BSSELFCHECKOUT,
            ) ||
              (company?.subscription?.plan.modules?.includes(
                EPlanModule.BSTICKET,
              ) &&
                company?.bsTicketEnablesStockControl && (
                  <QuantityContainer>
                    <Label>Qtde.</Label>
                    <ProductQuantity>{product.availableStock}</ProductQuantity>
                  </QuantityContainer>
                ))}
            {hasComplements || isPizza ? (
              <Price>
                <span> à partir de</span>
                {formatToMoney(
                  isPizza
                    ? Math.min(
                        ...product?.pizzaSizes?.map(current => current.price),
                      )
                    : product.suggestedPrice,
                )}
              </Price>
            ) : (
              <Price>{formatToMoney(product.suggestedPrice)}</Price>
            )}
          </Subtitle>
        </ProductInfo>
        {actions && (
          <Actions>
            {product.active ? (
              <FiPauseCircle onClick={handleInactivateProduct} />
            ) : (
              <FiPlayCircle onClick={handleInactivateProduct} />
            )}
            <AuthRole>
              <FiTrash onClick={handleDeleteProduct} />
            </AuthRole>
          </Actions>
        )}
      </Container>
    );
  }

  return (
    <ListContainer
      onClick={onClick}
      active={product.active}
      ref={containerRef}
      className={autoScroll ? 'focus-animation' : ''}
      actions={actions}
    >
      <Top>
        <Left>
          <ListImage>{productIcon}</ListImage>

          <VerticalWrapper>
            {product.sellerSku ? (
              <ListName>{`(${product.sellerSku}) ${product.title}`}</ListName>
            ) : (
              <ListName>{product.title}</ListName>
            )}
            <ListDescription>{product.description}</ListDescription>
          </VerticalWrapper>
        </Left>
        {!isPizza && (
          <ListPrice>{formatToMoney(product.suggestedPrice)}</ListPrice>
        )}
        {company?.subscription?.plan.modules?.includes(
          EPlanModule.BSSELFCHECKOUT,
        ) ||
          (company?.subscription?.plan.modules?.includes(
            EPlanModule.BSTICKET,
          ) &&
            company?.bsTicketEnablesStockControl && (
              <ListQuantityContainer>
                <ListLabel>Qtde.</ListLabel>
                <ListProductQuantity>
                  {product.availableStock}
                </ListProductQuantity>
              </ListQuantityContainer>
            ))}
        {actions && (
          <ListActions>
            {product?.active ? (
              <FiPauseCircle onClick={handleInactivateProduct} />
            ) : (
              <FiPlayCircle onClick={handleInactivateProduct} />
            )}
            <AuthRole>
              <FiTrash onClick={handleDeleteProduct} />
            </AuthRole>
          </ListActions>
        )}
      </Top>
      <Bottom>
        {product.categories.length > 0 && (
          <TypesContainer>
            {product.categories.map(c => (
              <CategoryBadge key={c.id}>{c.name}</CategoryBadge>
            ))}
            {product.subcategory?.name && (
              <SubcategoryBadge>{product.subcategory.name}</SubcategoryBadge>
            )}
            {product?.isCombo && <ComboBadge>COMBO</ComboBadge>}
          </TypesContainer>
        )}
      </Bottom>
    </ListContainer>
  );
};

export default Product;
