/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { ValueType, components } from 'react-select';
import Select from 'react-select/creatable';

import { useCompany } from '../../hooks/company';

import { selectStyles } from '../../styles/select';

import {
  Tip,
  Title,
  Footer,
  Header,
  Content,
  Container,
  Description,
  StyledModal,
  CancelButton,
  ConfirmButton,
} from './styles';

interface IReason {
  value: string;
  label: string;
}

interface IOrderCancelationModal {
  open: boolean;
  onCancel: () => void;
  onConfirm: (reason: string) => void;
}

StyledModal.setAppElement('#root');

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem opções</span>
    </components.NoOptionsMessage>
  );
};

const OrderCancelationModal: React.FC<IOrderCancelationModal> = ({
  open,
  onCancel,
  onConfirm,
}) => {
  const { standardCancelReasons, getStandardCancelReasons } = useCompany();

  const [localReasons, setLocalReasons] = useState<IReason[]>([]);

  const [selectedReason, setSelectedReason] = useState<IReason | null>(null);

  useEffect(() => {
    getStandardCancelReasons();
  }, [getStandardCancelReasons]);

  useEffect(() => {
    if (open) {
      setLocalReasons(
        standardCancelReasons.map(s => {
          return {
            value: uuid(),
            label: s.message,
          };
        }),
      );
    } else {
      setSelectedReason(null);
    }
  }, [standardCancelReasons, open]);

  const handleOnReasonChanged = useCallback((param: ValueType<IReason>) => {
    setSelectedReason(param as IReason);
  }, []);

  const handleOnReasonCreated = useCallback((reason: string) => {
    const id = uuid();

    setLocalReasons(prevState => {
      return [
        ...prevState,
        {
          value: id,
          label: reason,
        },
      ];
    });

    setSelectedReason({ value: id, label: reason });
  }, []);

  const handleOnConfirm = useCallback(() => {
    onConfirm(selectedReason?.label || '');
  }, [selectedReason, onConfirm]);

  return (
    <StyledModal
      isOpen={open}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Container>
        <Header>
          <Title>Escolha o motivo</Title>
          <Description>Você pode deixar o campo em branco.</Description>
          <Tip>Você pode, também, digitar um motivo específico.</Tip>
        </Header>
        <Content>
          <Select
            styles={selectStyles}
            value={selectedReason}
            options={localReasons}
            onChange={handleOnReasonChanged}
            placeholder="Selecione um motivo"
            onCreateOption={handleOnReasonCreated}
            formatCreateLabel={text => `Criar opção: ${text}`}
            components={{ NoOptionsMessage }}
          />
        </Content>
        <Footer>
          <CancelButton onClick={onCancel}>Voltar</CancelButton>
          <ConfirmButton onClick={handleOnConfirm}>Confirmar</ConfirmButton>
        </Footer>
      </Container>
    </StyledModal>
  );
};

export default OrderCancelationModal;
