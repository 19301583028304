import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react-web';

import { useInterval } from '../../hooks/time';

import touchAnimation from '../../assets/animations/touch.json';

import {
  AnimatedImage,
  Container,
  Image,
  PaymentCards,
  TouchAnimationContainer,
  TouchIndicator,
} from './style';
import { chooseBrandIcon } from '../../utils/icons';
import { useCompany } from '../../hooks/company';
import { normalizeCompanyPaymentCards } from '../../utils/totem';

interface ITotemPreview {
  images: { url: string }[];
  onClose: () => void;
}

const TotemPreview: React.FC<ITotemPreview> = ({ images, onClose }) => {
  const { company } = useCompany();

  const cards = useMemo(
    () =>
      company
        ? normalizeCompanyPaymentCards(
            company.paymentCards,
            company?.orderPaymentTypes.includes(`PIX`) ? [`PIX`] : [],
          )
        : [],
    [company],
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentSource, setCurrentSource] = useState<string>(images[0].url);
  const [newSource, setNewSource] = useState<string | null>(null);

  useInterval(() => {
    setCurrentIndex(old => {
      const result = old + 1;
      return result === images.length ? 0 : result;
    });
  }, 4000);

  const handleOnCurrentLoad = useCallback(() => {
    setNewSource(null);
  }, []);

  const handleOnAnimationEnd = useCallback(() => {
    if (newSource) {
      setCurrentSource(newSource);
    }
  }, [newSource]);

  useEffect(() => {
    setNewSource(images[currentIndex].url);
  }, [currentIndex, images]);

  return (
    <Container onClick={onClose}>
      <TouchIndicator>
        <TouchAnimationContainer>
          <Lottie
            options={{
              animationData: touchAnimation,
            }}
            speed={0.8}
            width="100%"
            height="100%"
          />
        </TouchAnimationContainer>
        <h3>Toque na tela para começar</h3>
      </TouchIndicator>

      <Image src={currentSource} onLoad={handleOnCurrentLoad} />

      {newSource && (
        <AnimatedImage
          src={newSource || ''}
          visible={!!newSource}
          onAnimationEnd={handleOnAnimationEnd}
        />
      )}

      <PaymentCards>
        {cards.map(({ description, paymentCardId }) => (
          <img
            src={chooseBrandIcon(description)}
            alt={description}
            key={paymentCardId}
          />
        ))}
      </PaymentCards>
    </Container>
  );
};

export default TotemPreview;
