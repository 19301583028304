import styled, { css } from 'styled-components';
import { Add } from '../MultipleHistoryImageInput/styles';
import { ToggleContainer as SettingsPageToggleContainer } from '../../pages/SettingsPage/styles';

export const ImageInput = styled(Add)`
  /* display: none; */
`;

export const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Images = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: auto;
  align-content: stretch;
  justify-items: center;
  align-items: center;
  grid-gap: 1rem;

  div {
    width: 100%;
    aspect-ratio: 9 / 16;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;

  &:hover div.delete {
    opacity: 1;
  }

  img,
  div {
    user-select: none;
    width: 100%;
    aspect-ratio: 9 / 16;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.delete {
    z-index: 2;
    opacity: 0;
    transition: opacity 400ms ease;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blue(2px);
  }

  &.dragging div.delete {
    opacity: 0 !important;
  }

  &.drag-target {
    border: 2px dashed ${({ theme }) => theme.palette.primary};

    img {
      opacity: 0.5;
    }
  }
`;

interface IImageLoaderProps {
  progress: number;
}

export const ImageLoader = styled.div<IImageLoaderProps>`
  transform-origin: top center;
  transform: scaleY(${({ progress }) => 1 - progress / 100});
  backdrop-filter: grayscale(100%) brightness(0.8);
`;

export const CropperContent = styled.div`
  background: ${({ theme }) => theme.palette.background_white};
  border-radius: 1rem;
  width: min(400px, 90vw);
`;

export const RemoveButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.palette.primary};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  border-radius: 1.25rem;

  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const ToggleContainer = styled(SettingsPageToggleContainer)`
  margin-top: 16px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    width: 80%;
  }

  overflow: hidden;
  border-radius: 0.25rem;
  gap: 0.4rem;
`;

interface IOptionsProps {
  active?: boolean;
}

export const Option = styled.button<IOptionsProps>`
  border: none;
  white-space: nowrap;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.palette.background_white};

  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.palette.primary};
      color: ${theme.palette.text_white};
      font-weight: 600;
    `}
`;
