import React from 'react';
import { Button, Icon, InputContainer, TimeWrapper } from './styles';

interface TimeDeliveryProps {
  icon: 'delivery' | 'pickUp';
  value: number;
  setValue: (e: number) => void;
  onClick: (e: number) => void;
}

const TimeDelivery: React.FC<TimeDeliveryProps> = ({
  icon,
  value = 0,
  onClick,
  setValue,
}) => {
  return (
    <TimeWrapper>
      <InputContainer>
        <Icon icon={icon} />
        <input
          value={value}
          onChange={e => setValue(Number(e.target.value))}
          type="number"
        />
      </InputContainer>
      <Button onClick={() => onClick(value)} />
    </TimeWrapper>
  );
};
export default TimeDelivery;
