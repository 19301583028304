import React, { useEffect, useState } from 'react';
import TimeDelivery from '../TimeDelivery';
import { InputTimeDeliveryMediumWrapper } from './styles';
import { ITimeCompany } from '../../models/ICompany';

interface ITimeDeliveryAndPickProps {
  times: ITimeCompany;
  onClick: (times: ITimeCompany) => void;
}
export const TimeDeliveryAndPick: React.FC<ITimeDeliveryAndPickProps> = ({
  times,
  onClick,
}) => {
  const [timeToDelivery, setTimeToDelivery] = useState<number>(
    times?.timeToDelivery || 0,
  );
  const [timeToPickup, setTimeToPickup] = useState<number>(
    times?.timeToPickup || 0,
  );

  useEffect(() => {
    setTimeToDelivery(times?.timeToDelivery);
    setTimeToPickup(times?.timeToPickup);
  }, [times]);

  return (
    <>
      <InputTimeDeliveryMediumWrapper>
        <TimeDelivery
          onClick={() => onClick({ timeToDelivery, timeToPickup })}
          value={timeToDelivery}
          setValue={setTimeToDelivery}
          icon="delivery"
        />
      </InputTimeDeliveryMediumWrapper>
      <InputTimeDeliveryMediumWrapper>
        <TimeDelivery
          onClick={() => onClick({ timeToDelivery, timeToPickup })}
          value={timeToPickup}
          setValue={setTimeToPickup}
          icon="pickUp"
        />
      </InputTimeDeliveryMediumWrapper>
    </>
  );
};
