import React, { useMemo } from 'react';
import { FiClock } from 'react-icons/fi';
import { HiOutlineArrowNarrowDown } from 'react-icons/hi';
import { Container, Action, Icon, AZIcon } from './styles';

export enum SortByButtonsEnum {
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_desc',
  ALPHABETICAL_ASC = 'alph_asc',
  ALPHABETICAL_DESC = 'alph_desc',
}

interface ISortByButtonsProps {
  id?: string;
  sortBy: SortByButtonsEnum;
  onChange: (sortBy: SortByButtonsEnum) => void;
}

const SortByButtons: React.FC<ISortByButtonsProps> = ({
  id,
  sortBy,
  onChange,
}) => {
  const isAlphabeticalActive = useMemo(() => {
    return (
      sortBy === SortByButtonsEnum.ALPHABETICAL_ASC ||
      sortBy === SortByButtonsEnum.ALPHABETICAL_DESC
    );
  }, [sortBy]);

  const isDateActive = useMemo(() => {
    return (
      sortBy === SortByButtonsEnum.DATE_ASC ||
      sortBy === SortByButtonsEnum.DATE_DESC
    );
  }, [sortBy]);

  const handleAlphabeticalClick = () => {
    if (sortBy === SortByButtonsEnum.ALPHABETICAL_ASC) {
      onChange(SortByButtonsEnum.ALPHABETICAL_DESC);
    } else {
      onChange(SortByButtonsEnum.ALPHABETICAL_ASC);
    }
  };

  const handleDateClick = () => {
    if (sortBy === SortByButtonsEnum.DATE_ASC) {
      onChange(SortByButtonsEnum.DATE_DESC);
    } else {
      onChange(SortByButtonsEnum.DATE_ASC);
    }
  };

  return (
    <Container id={id}>
      <Action active={isAlphabeticalActive} onClick={handleAlphabeticalClick}>
        <Icon
          rotate={
            isAlphabeticalActive &&
            sortBy !== SortByButtonsEnum.ALPHABETICAL_ASC
              ? 'true'
              : 'false'
          }
        >
          <AZIcon className="az-icon">
            <span>A</span>
            <span>Z</span>
          </AZIcon>
          <HiOutlineArrowNarrowDown size={20} className="rotate-svg" />
        </Icon>
      </Action>
      <Action active={isDateActive} onClick={handleDateClick}>
        <Icon
          rotate={
            isDateActive && sortBy !== SortByButtonsEnum.DATE_DESC
              ? 'true'
              : 'false'
          }
        >
          <FiClock size={20} className="rotate-svg" />
          <HiOutlineArrowNarrowDown size={20} />
        </Icon>
      </Action>
    </Container>
  );
};

export default SortByButtons;
