/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useCallback, useState } from 'react';

interface IRenderOptions {
  isDragging?: boolean;
  isDragTarget?: boolean;
  isTemp?: boolean;
}

export type DraggableRender<T> = (
  item: T,
  index: number,
  options: IRenderOptions,
) => ReactNode;

export interface IDraggableGridProps<T> {
  // eslint-disable-next-line react/require-default-props
  disableDrag?: boolean;
  items: T[];
  render: DraggableRender<T>;
  onDragEnd(newItems: T[]): void;
}

interface IDraggedItem<T> {
  item: T;
  index: number;
}

export function DraggableGrid<T>({
  items,
  render,
  onDragEnd,
  disableDrag,
}: IDraggableGridProps<T>): JSX.Element {
  const [temporaryItems, setTemporaryItems] = useState<T[]>();
  const [draggedItem, setDragedItem] = useState<IDraggedItem<T>>();

  const renderedItems = temporaryItems || items;

  const handleOnDragOver = useCallback(
    (e, item: T) => {
      e.preventDefault();
      if (!draggedItem || draggedItem?.item === item) {
        return;
      }
      const currentIndex = renderedItems.indexOf(draggedItem.item);
      const targetIndex = renderedItems.indexOf(item);

      if (currentIndex !== -1 && targetIndex !== -1) {
        const newItems = [...renderedItems];
        newItems.splice(currentIndex, 1);
        newItems.splice(targetIndex, 0, draggedItem.item);
        setTemporaryItems(newItems);
      }
    },
    [draggedItem, renderedItems],
  );

  const handleOnDragEnd = () => {
    onDragEnd(renderedItems);
    setDragedItem(undefined);
    setTemporaryItems(undefined);
  };

  return (
    <>
      {renderedItems.map((item, index) => (
        <div
          key={index.toString()}
          draggable={!disableDrag}
          onDragStart={() => setDragedItem({ item, index })}
          onDragOver={e => handleOnDragOver(e, item)}
          onDragEnd={handleOnDragEnd}
        >
          {render(item, index, {
            isDragging: !!draggedItem,
            isDragTarget: item === draggedItem?.item,
            isTemp: (temporaryItems?.length || 0) > 0,
          })}
        </div>
      ))}
    </>
  );
}
