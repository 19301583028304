/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { FiFilter } from 'react-icons/fi';
import DebouncePromise from 'awesome-debounce-promise';

import Select, { ValueType } from 'react-select';
import IOrder from '../../models/IOrder';

import { useSidebar } from '../../hooks/sidebar';
import { FilterTypes, useOrders } from '../../hooks/orders';

import Tour from '../../components/Tour';
import Order from '../../components/Order';
import Search from '../../components/Search';
import HelpButton from '../../components/HelpButton';
import PrintModal from '../../components/PrintModal';
import OptionsDialog from '../../components/OptionsDialog';
import LoadingAnimation from '../../components/LoadingAnimation';
import OrderSummaryModal from '../../components/OrderSummaryModal';
import OrderCancelationModal from '../../components/OrderCancelationModal';
import InfiniteScroll, { IScrollRef } from '../../components/InfiniteScroll';

import tourData from '../../tour/orders';
import { PageNames } from '../../enums/pages';

import {
  Main,
  Filter,
  Header,
  Content,
  Filters,
  PageInfo,
  PageName,
  Container,
  OrdersContainer,
  NoOrdersMessage,
  NoOrdersToLoadMessage,
  SelectContainer,
  FiltersContainer,
  SelectsContainer,
} from './styles';
import { useToast } from '../../hooks/toast';
import { selectStyles } from '../../styles/select';
import { EOrderOrigin } from '../../enums/order';
import { LocalStorage } from '../../enums/localstorage';

interface IFilterOptions {
  title: string;
  ref: FilterTypes;
  selected: boolean;
}

interface IOrderProps {
  order: IOrder;
}

interface IValueType<L, V> {
  label: L;
  value: V;
}

const originFilterOptions: IValueType<string, EOrderOrigin>[] = [
  {
    label: 'BS Food',
    value: EOrderOrigin.BSFOOD,
  },
  {
    label: 'BS Ticket',
    value: EOrderOrigin.BSTICKET,
  },
  {
    label: 'BS Self Checkout',
    value: EOrderOrigin.BSSELFCHECKOUT,
  },
];

const originSelectWidth = originFilterOptions[2].label.length * 11.8;

const typeFilterOptions: IValueType<string, string>[] = [
  {
    label: 'Todos',
    value: 'ON_TABLE,CHECKOUT,DRIVE_THRU',
  },
  {
    label: 'Na mesa',
    value: 'ON_TABLE',
  },
  {
    label: 'Retira',
    value: 'CHECKOUT',
  },
  {
    label: 'Drive thru',
    value: 'DRIVE_THRU',
  },
];

const typeSelectWidth = typeFilterOptions[3].label.length * 13;

const OrdersPage: React.FC<IOrderProps> = () => {
  const { addToast } = useToast();
  const { setSelectedPage, selectedPage } = useSidebar();
  const {
    orders,
    filter,
    hasMore,
    selectedOrder,
    isOrdersLoading,
    isLoadingMoreOrders,
    setSearch,
    setFilter,
    loadOrders,
    cancelOrder,
    loadMoreOrders,
    setSelectedOrder,
  } = useOrders();

  const [searchCriteria, setSearchCriteria] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isTourVisible, setIsTourVisible] = useState(false);
  const [orderToBeCanceled, setOrderToBeCanceled] = useState(0);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);

  const [isOrderSummaryModalVisible, setIsOrderSummaryModalVisible] = useState(
    false,
  );

  const [selectedOriginFilter, setSelectedOriginFilter] = useState<
    typeof originFilterOptions[number]
  >(
    originFilterOptions.find(
      option =>
        option.value === localStorage.getItem(LocalStorage.ORDERS_ORIGIN),
    ) || originFilterOptions[0],
  );

  const [selectedTypeFilter, setSelectedTypeFilter] = useState<
    typeof typeFilterOptions[number]
  >(
    typeFilterOptions.find(
      option => option.value === localStorage.getItem(LocalStorage.ORDERS_TYPE),
    ) || typeFilterOptions[0],
  );

  const scrollRef = useRef<IScrollRef>(null);

  const handleOnOrderSummaryModalClose = useCallback(() => {
    setIsOrderSummaryModalVisible(false);
    setSelectedOrder(null);
  }, [setSelectedOrder]);

  const handleOnOrderClick = useCallback(
    (order: IOrder) => {
      setSelectedOrder(order);
      setIsOrderSummaryModalVisible(true);
    },
    [setSelectedOrder],
  );

  const handleOnFilterChanged = useCallback(
    (currentFilter: FilterTypes | string) => {
      scrollRef.current?.scrollToTop();
      if (filter.includes(currentFilter as FilterTypes)) {
        const newFilters = filter.filter(f => f !== currentFilter);
        const result = newFilters.length <= 0 ? filter : newFilters;
        return setFilter(result, searchCriteria);
      }

      const newFilters = [...filter, currentFilter] as FilterTypes[];

      return setFilter(newFilters, searchCriteria);
    },
    [setFilter, searchCriteria, filter],
  );

  const handleOnPressClick = useCallback(
    (order: IOrder) => {
      setSelectedOrder(order);
      setPrintModalVisible(true);
    },
    [setSelectedOrder],
  );

  const searchDebounced = useRef(
    DebouncePromise(
      async (
        setFilterFunction: (filters: typeof filter, searchText: string) => void,
        selectedFilters: typeof filter,
        criteria: string,
      ) => {
        setLoadingSearch(false);
        setFilterFunction(selectedFilters, criteria);
      },
      800,
    ),
  );

  const handleOnClosePrintModal = useCallback(() => {
    setPrintModalVisible(false);
  }, []);

  const handleOnSearchCriteriaChanged = useCallback(
    async (text: string) => {
      setSearchCriteria(text);
      setLoadingSearch(true);

      if (!text) {
        setSearch(text);
        setLoadingSearch(false);
        setFilter(filter, '');
      } else {
        await searchDebounced.current(setFilter, filter, text);
      }
    },
    [setSearch, setFilter, filter],
  );

  const handleLoadMoreOrders = useCallback(() => {
    if (orders && !(isOrdersLoading || isLoadingMoreOrders)) {
      loadMoreOrders(orders[orders.length - 1].id);
    }
  }, [orders, loadMoreOrders, isOrdersLoading, isLoadingMoreOrders]);

  const handleHelpClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const handleOnOptionsDialogClosed = useCallback(() => {
    setIsOptionsDialogOpen(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setIsOptionsDialogOpen(true);
  }, []);

  const filterOptions: IFilterOptions[] = useMemo(() => {
    return [
      {
        ref: 'PREPARING',
        title: 'Em preparo',
        selected: filter.includes('PREPARING'),
      },
      {
        ref: 'IN_TRANSIT',
        title: 'Em entrega',
        selected: filter.includes('IN_TRANSIT'),
      },
      {
        ref: 'COMPLETED',
        title: 'Finalizados',
        selected: filter.includes('COMPLETED'),
      },
      {
        ref: 'CANCELED',
        title: 'Cancelados',
        selected: filter.includes('CANCELED'),
      },
    ];
  }, [filter]);

  const handleOnOrderCancelClick = useCallback((orderId: number) => {
    setOrderToBeCanceled(orderId);
  }, []);

  const handleOnCancelOrderCancel = useCallback(() => {
    setOrderToBeCanceled(0);
  }, []);

  const handleOnCancelOrder = useCallback(
    async (reason: string) => {
      try {
        await cancelOrder(orderToBeCanceled, reason);

        addToast({
          type: 'success',
          description: 'Pedido cancelado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao cancelar o pedido. Tente novamente.',
        });
      } finally {
        setOrderToBeCanceled(0);
      }
    },
    [orderToBeCanceled, cancelOrder, addToast],
  );

  const handleOnOriginFilterChanged = useCallback(
    (param: ValueType<typeof originFilterOptions[number]>) => {
      const origin = param as typeof originFilterOptions[number];

      setSelectedOriginFilter(oldOrigin => {
        localStorage.setItem(LocalStorage.ORDERS_ORIGIN, origin.value);
        loadOrders();
        return origin !== oldOrigin ? origin : oldOrigin;
      });
    },
    [loadOrders],
  );

  const handleOnTypeFilterChanged = useCallback(
    (param: ValueType<typeof typeFilterOptions[number]>) => {
      const type = param as typeof typeFilterOptions[number];

      setSelectedTypeFilter(oldType => {
        localStorage.setItem(LocalStorage.ORDERS_TYPE, type.value);
        loadOrders();
        return type !== oldType ? type : oldType;
      });
    },
    [loadOrders],
  );

  useEffect(() => {
    setSelectedPage(PageNames.ORDERS);
  }, [setSelectedPage]);

  useEffect(() => {
    return () => {
      setSearch('');
    };
  }, [setSearch]);

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Content>
        <Header>
          <PageInfo>
            <PageName>
              {selectedPage}
              <HelpButton onClick={handleHelpClick} />
            </PageName>
          </PageInfo>
          <Search
            id="orders-search"
            value={searchCriteria}
            loading={loadingSearch}
            onChange={handleOnSearchCriteriaChanged}
          />
          <FiFilter size={24} onClick={handleOpenDialog} className="filter" />
        </Header>
        <Main>
          <FiltersContainer>
            <Filters id="orders-filters">
              {filterOptions.map(option => (
                <Filter
                  key={option.ref}
                  selected={option.selected}
                  onClick={() => handleOnFilterChanged(option.ref)}
                >
                  {option.title}
                </Filter>
              ))}
            </Filters>

            <SelectsContainer>
              <SelectContainer width={originSelectWidth}>
                <Select
                  value={selectedOriginFilter}
                  styles={selectStyles}
                  options={originFilterOptions}
                  onChange={handleOnOriginFilterChanged}
                  placeholder="Origem"
                  menuPortalTarget={document.body}
                />
              </SelectContainer>
              {selectedOriginFilter.value === EOrderOrigin.BSSELFCHECKOUT && (
                <SelectContainer width={typeSelectWidth}>
                  <Select
                    value={selectedTypeFilter}
                    styles={selectStyles}
                    options={typeFilterOptions}
                    onChange={handleOnTypeFilterChanged}
                    placeholder="Tipo"
                    menuPortalTarget={document.body}
                  />
                </SelectContainer>
              )}
            </SelectsContainer>
          </FiltersContainer>
          {isOrdersLoading && orders.length <= 0 ? (
            <LoadingAnimation />
          ) : (
            <OrdersContainer
              id="orders-container"
              className="has-custom-scroll-bar-2"
            >
              {orders.length > 0 ? (
                <>
                  <InfiniteScroll
                    ref={scrollRef}
                    hasMore={hasMore}
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      overflowY: 'scroll',
                      paddingRight: '24px',
                      justifyContent: 'space-between',
                      alignContent: 'baseline',
                    }}
                    endComponent={
                      <NoOrdersToLoadMessage>
                        <span>Sem mais pedidos para serem carregados.</span>
                      </NoOrdersToLoadMessage>
                    }
                    loadMore={handleLoadMoreOrders}
                    loading={isLoadingMoreOrders}
                    loadingComponent={<LoadingAnimation small />}
                  >
                    {orders.map(order => (
                      <Order
                        type="ALL"
                        order={order}
                        key={order.id}
                        onClick={handleOnOrderClick}
                        onPressClick={handleOnPressClick}
                        onCancelClick={handleOnOrderCancelClick}
                      />
                    ))}
                  </InfiniteScroll>
                </>
              ) : (
                <NoOrdersMessage>
                  <span>Nenhum pedido feito ainda.</span>
                </NoOrdersMessage>
              )}
            </OrdersContainer>
          )}
        </Main>
        <OrderSummaryModal
          order={selectedOrder}
          visible={isOrderSummaryModalVisible}
          onClose={handleOnOrderSummaryModalClose}
        />
        <OptionsDialog
          title="Filtros"
          options={filterOptions}
          isOpen={isOptionsDialogOpen}
          onConfirm={handleOnFilterChanged}
          onClose={handleOnOptionsDialogClosed}
        />
      </Content>
      {isTourVisible && <Tour steps={tourData} onFinish={handleTourFinish} />}
      <PrintModal open={printModalVisible} onCancel={handleOnClosePrintModal} />
      {orderToBeCanceled > 0 && (
        <OrderCancelationModal
          open={orderToBeCanceled > 0}
          onConfirm={handleOnCancelOrder}
          onCancel={handleOnCancelOrderCancel}
        />
      )}
    </Container>
  );
};

export default OrdersPage;
