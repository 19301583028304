import styled, { css } from 'styled-components';

interface IFilterProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background: #f4f4f6;
  position: relative;

  transition: all 0.2s;

  @media (max-width: 1270px) {
    width: 100vw;
  }

  @media (max-width: 480px) {
    padding-bottom: 48px;
  }
`;

export const InnerContent = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: height 0.4s;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-height: 100px;
  padding: 0 32px;
  padding-top: 24px;
  gap: 16px;
`;

export const PageInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageName = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const PageDescription = styled.span`
  margin-left: 64px;
  color: #0008;
  margin-top: 9px;
  margin-right: 16px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Main = styled.main`
  padding: 0 32px;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: start;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;

  padding: 32px;
  padding-bottom: 80px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const AddProductButton = styled.button`
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-self: flex-end;

  border: 0;
  color: #fff;
  padding: 8px;
  background: #db1622;

  span {
    font-size: 14px;

    &::after {
      content: 'Adicionar novo';
      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 12px;
    right: 12px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 32px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
    justify-content: center;
    padding: 8px;
    z-index: 10;
    svg {
      width: 32px;
      height: 32px;
    }

    span::after {
      display: none;
      content: '';
      margin-left: 0;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-top: 32px;

  width: 100%;

  span + span {
    margin-left: 32px;
  }
`;

export const Filter = styled.span<IFilterProps>`
  padding: 6px;
  padding-bottom: 16px;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 3px solid #db1622;
        `
      : css`
          border-bottom: 1px solid transparent;
        `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border-bottom: 1px solid #1b1c1e;
      `};
  }
`;

export const MainHeader = styled.div`
  display: flex;
  padding: 0 32px;
  width: 100%;

  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 32px;

  border-bottom: 1px solid #ccc;

  button + button {
    border-left: 1px solid #000;
    padding-left: 8px;
    margin-left: 8px;
  }

  @media (max-width: 600px) {
    border: 0;
    margin-top: 0;
    padding-bottom: 8px;

    width: 100%;
  }
`;

export const DisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: none;
`;

export const FlexPlaceholder = styled.div`
  height: 0;
  width: 32%;
`;

export const EmptyMessageContainer = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;
