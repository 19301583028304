import React, { useMemo } from 'react';
import Lottie from 'lottie-react-web';
import { getAnimation, loadingAnimations } from '../../utils/loadingAnimations';

import { StyledModal, Content, Animation } from './styles';
import { LoadingAnimations } from '../../enums/loadingAnimation';

interface IChooseLoadingAnimationModal {
  isOpen?: boolean;
  onClose?: () => void;
  onSelect?: (value: LoadingAnimations) => void;
}

const ChooseLoadingAnimationModal: React.FC<IChooseLoadingAnimationModal> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const options = useMemo(
    () =>
      Object.keys(loadingAnimations).map(key => (
        <Animation
          key={key}
          onClick={() => onSelect && onSelect(key as LoadingAnimations)}
        >
          <Lottie
            options={{
              animationData: getAnimation(key as LoadingAnimations),
              loop: true,
              autoplay: false,
            }}
            width={100}
            height={100}
          />
        </Animation>
      )),
    [onSelect],
  );

  return (
    <StyledModal
      isOpen={isOpen || false}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, .6)',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Content>{options}</Content>
    </StyledModal>
  );
};

export default ChooseLoadingAnimationModal;
