import React, { useState, useContext, useCallback, createContext } from 'react';
import api from '../services/api';
import { useCompany } from './company';

interface StatusContextData {
  isOpen: boolean;
  sendWhatsapp: boolean;
  toggleIsOpen: () => Promise<void>;
  toggleSendWhatsapp: () => Promise<void>;
  checkStatus: () => Promise<void>;
}

const StatusContext = createContext<StatusContextData>({} as StatusContextData);

export const StatusProvider: React.FC = ({ children }) => {
  const { company } = useCompany();
  const [isOpen, setIsOpen] = useState(false);
  const [sendWhatsapp, setSendwhatsapp] = useState(false);

  const checkStatus = useCallback(async () => {
    const response = await api.get<boolean>('/companies/is-open');
    setIsOpen(response.data);
  }, []);
  const toggleIsOpen = useCallback(async () => {
    await api.patch(
      `restricted/companies/${company?.id}/${isOpen ? 'close' : 'open'}`,
      {},
    );
    setIsOpen(!isOpen);
  }, [company, isOpen]);

  const toggleSendWhatsapp = useCallback(async () => {
    await api.patch(
      `restricted/companies/${company?.id}/send-whatsapp/${
        sendWhatsapp ? 'deactivate' : 'activate'
      }`,
      {},
    );
    setSendwhatsapp(!sendWhatsapp);
  }, [company, sendWhatsapp]);

  return (
    <StatusContext.Provider
      value={{
        isOpen,
        sendWhatsapp,
        toggleIsOpen,
        toggleSendWhatsapp,
        checkStatus,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export function useStatus(): StatusContextData {
  const context = useContext(StatusContext);

  if (!context) {
    throw new Error('useStatus must be used within StatusProvider');
  }

  return context;
}
