import ICardBrand from '../models/ICardBrand';

export function getPaymentCardsWithAdditionals(
  companyCards: ICardBrand[],
  additional: string[] = [],
): ICardBrand[] {
  return companyCards.concat(
    additional.map(description => ({
      delivery: true,
      local: true,
      description,
      paymentCardId: Math.floor(Math.random() * 1000),
      paymentCardType: description,
    })),
  );
}

export function normalizeCompanyPaymentCards(
  companyCards: ICardBrand[],
  additional: string[] = [],
): ICardBrand[] {
  const set = new Set();
  const reg = /.*\s-\s/;

  const cards = getPaymentCardsWithAdditionals(companyCards, additional);

  return cards.reduce((acc, current) => {
    if (!current?.local) return acc;

    const label = current.description.replace(reg, '').split(' ')[0];

    if (set.has(label)) return acc;

    set.add(label);

    return [...acc, current];
  }, [] as ICardBrand[]);
}
