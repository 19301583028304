import styled from 'styled-components';

export const InputTimeDeliveryMediumWrapper = styled.div`
  @media (max-width: 1270px) {
    display: block;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;
